import {
  ForgetPasswordPayload,
  ForgetPasswordResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SignInPayload,
  SignInResponse,
  SignupPayload,
  SignupResponse,
  TokenCheckPayload,
  TokenCheckResponse,
} from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";
export const serverURL = "https://api-fleet-management.1now.app";
// export const serverURL = "https://fleet-management-dev.1now.app";
//  export const serverURL = 'http://192.168.100.222:8000';

const baseUrl = serverURL + "/api/";
const jsonHeaders = {
  "Content-Type": "application/json",
};
export const SignUpFun = async (
  payload: SignupPayload
): Promise<{ data?: SignupResponse; error?: string }> => {
  try {
    const response = await customAxios.post("/api/auth/signup/", payload);
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error: error.response.data.detail || "An error occurred during signup.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const SignInFun = async (
  payload: SignInPayload
): Promise<{ data?: SignInResponse; error?: string }> => {
  try {
    const response = await customAxios.post("/api/auth/signin/", payload);
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error: error.response.data.detail || "An error occurred during signin.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const ForgetPassFun = async (
  payload: ForgetPasswordPayload
): Promise<{ data?: ForgetPasswordResponse; error?: string }> => {
  try {
    const response = await customAxios.post(
      "/api/auth/forget-password/",
      payload
    );
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred during sending reset link.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const ResetPassFun = async (
  payload: ResetPasswordPayload
): Promise<{ data?: ResetPasswordResponse; error?: string }> => {
  try {
    const response = await customAxios.post(
      "/api/auth/reset-password/",
      payload
    );
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred during resetting password.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const TokenCheckFun = async (
  payload: TokenCheckPayload
): Promise<{ data?: TokenCheckResponse; error?: string }> => {
  try {
    const response = await customAxios.post(
      "/api/auth/check-token-validity/",
      payload
    );
    return { data: response.data }; // Return response data if successful
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error: error.response.data.detail || "Token Validity Failed.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

interface ErrorResponse {
  message: string;
  detail?: string;
}

async function handleResponse<T>(response: Response): Promise<T> {
  // if (response.status === 401) {
  //   toast.error('Session expired, Please login again');
  // }
  if (response.status === 204) {
    return undefined as unknown as T; // Return undefined for no content, adjust if needed based on T.
  }
  if (!response.ok) {
    const errorData: ErrorResponse = (await response.json()) as ErrorResponse;
    throw new Error(
      errorData.message || errorData.detail || "Server responded with an error"
    );
  }
  const data: unknown = await response.json();
  // Here you might want to add runtime type checks depending on what T could be
  return data as T; // This assumes that the calling code knows what type it expects.
}

export async function googleSignin(userobj: object) {
  const body = JSON.stringify({ ...userobj });
  const response = await fetch(`${baseUrl}auth/signin/`, {
    method: "POST",
    headers: jsonHeaders,
    body,
  });
  return handleResponse(response);
}
