import rightImg from "images/image.jpg";
import comparsionImg from "images/Comparrsion.png";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionComparison from "containers/PageAbout/Comparison";
import logo from "images/image.png";
import { Helmet } from "react-helmet";

function PageHome3() {
  return (
    <>
      <Helmet>
        <title>Go Places Car Rental | South Florida</title>
        <meta
          name="description"
          content="An all NEW Car Rental Experience: Places Rentals South Florida offers contactless pickup and drop-off getting you on your way faster than ever! Economy Sedans, SUVs, Exotic, MiniVan + more "
        />
      </Helmet>

      <div className="nc-PageHome3 relative overflow-hidden">
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />
        {/* <DownloadLink /> */}

        {/* SECTION HERO */}

        <SectionHero3 className="" />

        <div className="container relative pt-16 pb-8">
          <SectionGridFeaturePlaces />
        </div>
        <div className="md:hidden flex justify-center">
          <img width={150} src={logo} />
        </div>
        <div className="container relative py-16">
          <SectionComparison
            rightImg={comparsionImg}
            heading="Why Go Places with us?"
            btnText=""
            subHeading={globalJson?.about_us_para}
          />{" "}
        </div>

        <div className="container relative" style={{ padding: "0px" }}>
          {/* ======== BG GLASS ======== */}
          <BgGlassmorphism />

          <div className="container py-8 lg:py-14 space-y-8 lg:space-y-14">
            {/* <SectionFounder /> */}
            <div className="relative py-16 mb-20 lg:mb-36">
              <BackgroundSection />
              <SectionClientSay uniqueClassName="PageAbout_" />
            </div>
            <SectionHero
              rightImg={rightImg}
              heading="About Us."
              btnText=""
              subHeading={globalJson?.about_us_para}
            />
            {/* <SectionSubscribe2 /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome3;
