import { serverURL } from 'api/auth/auth';
import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// Create an Axios instance
const customAxios: AxiosInstance = axios.create({
  //production
  baseURL: serverURL, 
  
  // development
  // baseURL: 'https://fleet-management-dev.1now.app', 
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
customAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('access_token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle response
    return response;
  },
  (error: AxiosError) => {
    // Handle response error
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized, logging out...');
      // Handle 401 Unauthorized error
    }
    return Promise.reject(error);
  }
);

export default customAxios;
